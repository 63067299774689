$(function () {
/*
        // create a manager for that element
        var mcInsideMenu = new Hammer.Manager(document.body,{
            touchAction : 'auto'
        });
        // create a recognizer
        var swipe = new Hammer.Swipe();
        mcInsideMenu.add(swipe);
        // subscribe to events
        mcInsideMenu.on('swipedown', function (e) {
            e.preventDefault();
            location.reload();
        });
*/
})